
// Constants

class ListingMap {
    constructor() {
        // Set defaults
        this.mapCenter = [36.579403, -97.701766];
        this.zoomLevel = 5;
        this.enablePopups = true;

        // check for map options
        var mapInitDiv = document.getElementById('map-init-div');
        if (mapInitDiv) {
            var latitude = mapInitDiv.getAttribute('latitude');
            var longitude = mapInitDiv.getAttribute('longitude');
            var initZoomLevel = mapInitDiv.getAttribute('zoom-level');
            var enablePopups = mapInitDiv.getAttribute('enable-popups');
            if (latitude && longitude) { this.mapCenter = [latitude, longitude]; }
            if (initZoomLevel) { this.zoomLevel = initZoomLevel; }
            if (enablePopups && enablePopups == "false") { this.enablePopups = false; }
        }
        // create a map for element with id event-listing-map
        this.map = L.map('leaflet-map').setView(this.mapCenter, this.zoomLevel);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);
        //var marker = L.marker([30.274271, -97.740317]).addTo(this.map);
        document.addEventListener("DOMContentLoaded", this.addMarkers.bind(this));
    }

    addMarker(latitude, longitude) {
        var marker = L.marker([latitude, longitude]).addTo(this.map);
    }

    addMarkers() {
        var cluster = L.markerClusterGroup({
            showCoverageOnHover: false,
            zoomToBoundsOnClick: false,
            spiderfyOnMaxZoom: true,
            removeOutsideVisibleBounds: true,
            maxClusterRadius: 40,
            spiderfyPolylineOptions: { weight: 1.5, color: '#442A88', opacity: 0.5 }, // FCECAC FFE77F EE7E7E EB4C67 B1A6CE 442A88
            iconCreateFunction: this.iconCreationFunction
        });
        var coordinateElements = document.getElementsByClassName('coordinate-data');
        var postLinks = document.getElementsByClassName('post-url');
        var markers = [];
        for (var i = 0; i < coordinateElements.length; i++) {
            var latitude = coordinateElements[i].getAttribute('latitude');
            var longitude = coordinateElements[i].getAttribute('longitude');
            var listingName = coordinateElements[i].getAttribute('listingName');
            var listingOwnerName = coordinateElements[i].getAttribute('listingOwnerName');
            var postUrl = "";
            if (postLinks.length > i) { postUrl = postLinks[i].getAttribute('href'); }

            // Build marker
            var marker = L.marker([latitude, longitude], {
                icon: new L.DivIcon({ html: '<div class="map-icon-dot"></div>', className: 'map-icon', iconSize: new L.Point(30, 30) }),
                title: listingOwnerName,
                alt: "map marker for " + listingOwnerName
            })

            // Build Popup
            if (this.enablePopups) {
                var popupContent = `
                    <div style="margin: 0; width: 271px;">
                        <a class="map-popup-container" href="${postUrl}">
                            <div class="map-popup-headings">
                                <h3>${listingName}</h3>
                                <h4>${listingOwnerName}</h4>
                            </div>
                        </a>
                    </div>
                `;
                var popup = L.popup()
                    .setLatLng([latitude, longitude])
                    .setContent(popupContent)

                marker.bindPopup(popup);
            }

            markers.push(marker);
        }
        cluster.addLayers(markers);
        this.map.addLayer(cluster);
    }

    iconCreationFunction(cluster) {
        var childCount = cluster.getChildCount();
        return new L.DivIcon({ html: '<div><span >' + childCount + '</span></div>', className: 'map-cluster-icon', iconSize: new L.Point(40, 40) });
    }
}

export default ListingMap
